<template lang="pug">
include ../pug/svg
main#shop
	ul#left(v-if="albums.length")
		li
			button#filter.icon.pulse(type="button")
				+svg(svg-filename="iconFilter")
				span Filter
		li
			button#search.icon.pulse(type="button" @click="showSearch")
				+svg(svg-filename="iconSearch")
				span Search
		li
			button#sort.icon.pulse(type="button")
				+svg(svg-filename="iconSort")
				span Sort
	div
		Album(v-for="(album, index) in albums" :key="album.id" :album="album" :index="index" :pandp="pandp" @viewAlbum="setPreviewAlbum")
transition(name="fademodal")
	div#preview.modal(v-show="previewAlbum")
		div
			ul.modal-nav
				li
					button.icon.pulse(type="button" @click="setPreviewAlbum(null)")
						+svg(svg-filename="iconClose")
						span Close Album
				li
					BasketButton
				li(v-if="albums.length>1")
					button.prev.icon.pulse(type="button" @click="prevAlbum" @mouseover="flipArtwork=null")
						+svg(svg-filename="iconArrow")
						span Previous Album
				li(v-if="albums.length>1")
					button.next.icon.pulse(type="button" @click="nextAlbum" @mouseover="flipArtwork=null")
						+svg(svg-filename="iconArrow")
						span Next Album
			div.modal-bod(v-if="previewAlbum" :class="{swap:swapAlbum}")
				div.c1.blurb
					picture(v-if="previewAlbum.artistPhoto")
						img(:src="previewAlbum.artistPhoto" :alt="previewAlbum.artistName")
					hgroup
						h2 {{previewAlbum.title}}
						h3 {{previewAlbum.artistName}}
						ul(v-if="previewAlbum.albumArtists")
							li(v-if="previewAlbum.albumArtists[0]?.facebook")
								a.facebook(:href="facebookUrl(previewAlbum.albumArtists[0])" target="_blank") Facebook
									+svg(svg-filename="iconFacebook")
							li(v-if="previewAlbum.albumArtists[0]?.twitter")
								a.twitter(:href="twitterUrl(previewAlbum.albumArtists[0])" target="_blank") Twitter
									+svg(svg-filename="iconTwitter")
					div(v-html="previewAlbum.description")
					dl(v-if="previewAlbum.estPreorder||previewAlbum.estShipping")
						dt(v-if="previewAlbum.estPreorder") Pre-order Date
						dd(v-if="previewAlbum.estPreorder") {{previewAlbum.estPreorder}}
						dt(v-if="previewAlbum.estShipping") Est. Shipping Date
						dd(v-if="previewAlbum.estShipping") {{previewAlbum.estShipping}}
				div.c2
					div.artwork(:class="{flipped:flipArtwork===true,reflipped:flipArtwork===false}")
						span.sku {{previewAlbum.trrSku}} / {{previewAlbum.catNum}}
						div
							div.album.front
								em {{stockLabel(previewAlbum)}}
									//-strong {{ previewAlbum.stock }}
								picture
									img(:src="previewAlbum.artworkCover" :alt="'Album front cover - '+previewAlbum.artistName+', '+previewAlbum.title")
								button.icon.pulse.flip(type="button" v-if="previewAlbum.artworkBack" @click="toggleFlip()" @mouseover="cancelPreviewHint=true" :class="{hint:!cancelPreviewHint}")
									+svg(svg-filename="iconFlip")
							div.album.back
								em {{stockLabel(previewAlbum)}}
									//-strong {{ previewAlbum.stock }}
								picture
									img(:src="previewAlbum.artworkBack" :alt="'Album back cover - '+previewAlbum.artistName+', '+previewAlbum.title")
								button.icon.pulse.flip(type="button" @click="toggleFlip()")
									+svg(svg-filename="iconFlip")
					ul.toolbar
						li.but-price
							Price(:price="vatPrice(previewAlbum.price)" vat="inc")
							button.text.pulse(type="button" @click="addAlbumToBasket(previewAlbum)" :disabled="!previewAlbum.mintsoftProductId || !previewAlbum.stock || previewAlbum.state === 'soon'") Buy Now
						li.but-view(v-if="user?.roleId===4")
							router-link.but.text.pulse(:to="{ name:'Album', params: { slug: previewAlbum.slug } }")
								span View Album
						li.but-play(v-if="hasAudio")
							button.icon.pulse(type="button" @click="togglePlay()" @mouseover="cancelPreviewHint=true" :class="{pulsate:previewTrack,hint:!cancelPreviewHint}")
								+svg(svg-filename="iconListen")
								span Listen
				div.c3.tracklist
					div.selector(v-show="trackList.length")
						div
							h2#h22(aria-label="Select record number") Record
							ul#record(aria-labelledby="h22")
								li(v-for="(num, index) in recordNumbers" :key="index")
									button(type="button" aria-label="Record {{num}}" :class="{on: num == selectedRecord}" @click="swapRecord(num)") {{num}}
						div
							h2#h21(aria-label="Select record side") Side
							ul#side(aria-labelledby="h21")
								li(v-for="(side, index) in recordSides" :key="index")
									button(type="button" aria-label="{{side}} Side" :class="{on: side == selectedSide}" @click="swapSide(side)") {{side}}
					ol.tracks(:class="{swap:swapTrack}")
							li.track(v-show="inTrackList(track.id)" v-for="(track, index) in previewAlbum.albumTracks" :key="track.id" aria-label="Record {{track.record}} {{track.side}} side track {{index}}")
								TrackPlayer(:track="track" :album="previewAlbum" @dataFromTrackPlayer="updatePlaying")
div#search.modal(v-if="searchActive")
	div
		div.modal-nav
			button.icon.pulse(type="button" @click="hideSearch")
				+svg(svg-filename="iconClose")
				span Close Search
		div.modal-bod
			div.filter()
			div.search()
				input(type="text" placeholder="Search by title" v-model="title")
				button(type="button" @click="searchTitle") Search
			div.sort()
</template>

<script>
import Album from '../components/Album';
import Price from '../components/Price';
import TrackPlayer from '../components/TrackPlayer';
import BasketButton from '../components/BasketButton';
import PaymentService from '../services/PaymentService';

export default {
	name: 'Shop',
	emits: ['addedToBasket'],
	components: {
		Album,
		Price,
		TrackPlayer,
		BasketButton,
	},
	data() {
		return {
			pandp: [],
			title: '',
			previewIndex: -1,
			previewAlbum: null,
			previewTrack: null,
			selectedRecord: 1, 
			selectedSide: 'a',
			searchActive: false,
			flipArtwork: null, // null is inportant
			cancelPreviewHint: false,
			swapAlbum: false,
			swapTrack: false,
		};
	},
	async created() {
		const {data} = await PaymentService.getDelivery();
		this.pandp = data;
	},		
	mounted() {
		// load albums from database
		this.refreshAlbums();
	},
	methods: {
		facebookUrl(artist) {
			return (artist.facebook) ? 'https://www.facebook.com/' + artist.facebook : null;
		},
		twitterUrl(artist) {
			return (artist.twitter) ? 'https://twitter.com/' + artist.twitter : null;
		},
		vatCharge(price) {
			let vat = (price * this.pandp.vatPct);
			return Math.round((vat + Number.EPSILON) * 100) / 100;
		},
		vatPrice(price) {
			return (price + this.vatCharge(price)).toFixed(2);
		},
		async refreshAlbums() {
			// load albums from database
			await this.$store.dispatch('albums/dbAllAlbums');
			// decrement stock quantities if in basket
			this.$store.dispatch('basket/initiateStock');
		},
		toggleFlip() {
			this.flipArtwork = !this.flipArtwork;
		},
		togglePlay() {
			if (this.previewTrack) {
				this.previewTrack.playing = false;	
				this.previewTrack = null;
				
			} else {
				this.swapRecord(1);
				this.swapSide('a');
				this.previewTrack = this.previewAlbum.albumTracks[0];				
				this.previewTrack.playing = true;	
			}
		},
		updatePlaying(trackId, isPlaying) {
			// triggered from TrackPlayer via $emit
			for (let track of this.previewAlbum.albumTracks) {
				if (isPlaying) {
					// track started
					if (track.id === trackId) {
						this.previewTrack = track;
						this.swapSide(track.side);
						this.swapRecord(track.record);
						track.playing = true;
						
					} else {
						// stop other tracks playing 
						track.playing = false;
					}					
					
				} else {
					// track ended
					if (track.id === trackId) {
						this.previewTrack = null;
						track.playing = false;						
						
					} else {
						// play next track
						if (!this.previewTrack) {
							this.previewTrack = track;						
							this.swapSide(track.side);
							this.swapRecord(track.record);
							track.playing = true;
						}
					}
				}
			}
		},
		swapRecord(record) {
			if (record === this.selectedRecord) {
				return;
			}
			this.swapTrack = true;
			this.swapSide('a');
			
			setTimeout(function() { 
				this.selectedRecord = record;
			}.bind(this), 300);
			
			setTimeout(function() { 
				this.swapTrack = false;
			}.bind(this), 600);
		},
		swapSide(side) {
			if (side === this.selectedSide) {
				return;
			}
			
			this.swapTrack = true;
			
			setTimeout(function() { 
				this.selectedSide = side;
			}.bind(this), 300);
			
			setTimeout(function() { 
				this.swapTrack = false;
			}.bind(this), 600);
		},
		showSearch() {
			document.body.classList.add('modal-search');
			this.searchActive = true;
		},
		hideSearch() {
			document.body.classList.remove('modal-search');
			this.searchActive = false;			
		},
		addAlbumToBasket(album) {
			this.$store.dispatch('basket/addToBasket', {item: album, type: 'album'});
			// notify App.vue
			this.$emit('addedToBasket');			
		},
		setPreviewAlbum(album, index) {
			if (this.previewTrack) {
				// stop audio playing
				this.previewTrack.playing = false;
			}
			
			// reinit hints
			this.cancelPreviewHint = true;
			
			setTimeout(function(){
				// NOTE: delay to allow audio stop command to complete (prevents TrackPlayer 'currentSec' watch error)
				if (!album) {
					// reset to default vals
					this.previewIndex = -1,
					this.previewAlbum = null;
					this.previewTrack = null;
					
					document.body.classList.remove('modal-preview');				
					
				} else {
					this.previewIndex = index,				
					this.previewAlbum = album;
					this.previewTrack = null;
					
					document.body.classList.add('modal-preview');				
				}
				
				// reset track list controls
				this.swapRecord(1);	
				this.swapSide('a');
				
				// reset artwork state
				this.flipArtwork = null;
				// reset hints
				this.cancelPreviewHint = false;
				//
				this.swapAlbum = false;
				
			}.bind(this), 60);
		},
		setHex(hex) {
			if (hex) {
				console.log(hex);
			}
//			this.$store.dispatch('css/background', hex);
		},
		prevAlbum() {
			this.swapAlbum = true;
			
			let index = this.previewIndex - 1;
			let album = this.albums[index] || null;
			
			if (!album) {
				index = this.albums.length - 1;
				album = this.albums[index];
			}
			
//			this.flipArtwork = null;
			
			setTimeout(function() {
				this.setPreviewAlbum(album, index);			
			}.bind(this), 1000);
		},
		nextAlbum() {
			this.swapAlbum = true;
			
			let index = this.previewIndex + 1;
			let album = this.albums[index] || null;
			
			if (!album) {
				index = 0;
				album = this.albums[index];
			}
			
//			this.flipArtwork = null;
			
			setTimeout(function() {
				this.setPreviewAlbum(album, index);			
			}.bind(this), 1000);
		},
		searchTitle() { // TODO: use filter instead??
		},
		inTrackList(trackId) {
			const track = this.trackList.find(t => t.id === trackId);
			return (track) ? true : false;
		},
	},
	computed: {
		user() {
			return this.$store.state.auth.user;
		},
		stockLabel() {
			return (album) => {
				let label;

				if (album.state === 'soon') {
					label = 'Coming Soon';

				} else if (album.state === 'funding' && album.stock) {
					label = 'Funded';				

				} else if (album.state === 'preorder' && album.stock) {
					label = 'Pre-Order';

				} else if (album.state === 'stock' && album.stock > 25) {
					label = 'In Stock';

				} else if (album.state === 'stock' && album.stock && album.stock <= 25) {
					label = 'Low Stock';

				} else {
					label = 'Out of Stock';
				}				
				
				return label;
			};
		},		
		hasAudio() {
			const audios = (this.previewAlbum?.albumTracks) ? this.previewAlbum.albumTracks.filter(t => { return t.file !== null }) : null;
			return audios.length ? true : false; 
		},
		albums() {
			return this.$store.getters['albums/allAlbums'];
		},
		trackList() {
			// return tracks for selected record side
			var tracks = this.previewAlbum.albumTracks.filter(track => {
				return (track.side === this.selectedSide && track.record === this.selectedRecord);
			});
			
			return tracks;	
		},
		recordSides() {
			// return all sides
			const sides = this.previewAlbum.albumTracks.map(({ side }) => side);
			// make unique
			return new Set(sides);
		},
		recordNumbers() {
			// get all record numbers
			const records = this.previewAlbum.albumTracks.map(({ record }) => record);
			// make unique
			return new Set(records);
		},
	},	
}
</script>

<style lang="scss">
#shop {
	overflow: hidden; // prevent scrollbars if vinyl extends too far on hover
	padding: 20px 110px 160px 110px;
	>div {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;
		margin: auto;
	}
	.album {
		margin-bottom: 160px;
		@media only screen and (max-width: 470px) {
			em:not(.qty),
			hgroup {
				left: 30px;
			}
		}
		&:before,
		picture {
			transition: .3s ease-in-out;
			transform-origin: top right;
		}
	}
	@media only screen and (max-width: 470px) {
		padding: 120px 40px 160px 40px;
	}
	@media only screen and (max-width: 829px) { // 1 column
		>div {
			max-width: 490px;
		}
		.album:nth-child(even) {
			margin-left: auto;
		}
	}
	@media only screen and (min-width: 830px) and (max-width: 1169px) { // 2 column
		>div {
			max-width: 680px;
		}
		.album {
			&:nth-child(2n+1) { // column 1 offset
				transform: translateY(0);
			}
			&:nth-child(2n+2) { // column 2 offset
				transform: translateY(80px);
			}
		}
	}
	@media only screen and (min-width: 1170px) { // 3 column
		>div {
			max-width: 990px;
		}
		.album {
			&:nth-child(3n+1) { // column 1 offset
				transform: translateY(80px);
			}
			&:nth-child(3n+2) { // column 2 offset
				transform: translateY(160px);
			}			
		}
	}
}
#preview {
//	z-index: 110;
	>div {
//		display: flex;
//		max-width: 1400px;
//		margin: auto;
	}
	li {
		list-style-type: none;
	}
/*	.modal-nav {
		z-index: 1;
		li {
			@media only screen and (min-width: 540px) {
				&:first-child {
					margin-right: auto;
				}
			}
			@media only screen and (max-width: 539px) {
				&:nth-child(2) {
					margin-right: auto;
				}
			}
			&:nth-child(n+2) {
				margin-left: 10px;
			}
		}
	}
	.prev {
		svg {
			transform: rotate(180deg);
		}
	}*/
	.modal-bod {
		display: flex;
		width: 100%;
		.c1,
		.c3 {
			transition: all .5s ease-in-out;
		}
		.artwork {
			transition: all .15s .35s cubic-bezier(.47,1.64,.41,.8);
		}
		&.swap {
			.c1,
			.c3 {
				opacity: 0;
			}
			.artwork {
				transform: translateY(-100%) translateY(-100px);
				transition-timing-function: ease-in;
			}
		}
		@media only screen and (max-width: 939px) {
			flex-direction: column;
			max-width: 500px;
			margin: auto;
			.c1 { // blurb
				padding: 60px 0 20px 0;
				order: +1;
			}
		}
		@media only screen and (min-width: 940px) {
			justify-content: space-between;
			>* {
				width: 33.33%;
				max-width: 400px;
			}
			.c1 {
				padding-top: 20px;
			}
			.c2 {
				flex-grow: 1;
				margin: 0 40px;
				max-width: 680px;
			}
		}
	}
	.c2 {
		position: relative;
		padding-top: 20px;
	}
	.c3 {
		overflow: hidden;
	}
/*	.selector {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
		color: #FFF;
		h2 {
			font-size: 1.4rem;
			font-weight: 500;
			margin-bottom: 5px;
		}
		ul {
			position: relative;
			display: inline-flex;
			overflow: hidden;
			&:after {
				content: '';
				pointer-events: none;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: 2px solid #FFF;
				border-radius: 13px;
			}
		}
		li {
			text-transform: uppercase;
		}
		button {
			width: 40px;
			height: 26px;
			color: #FFF;
			font-size: 1.4rem;
			font-weight: 500;
			text-transform: uppercase;
			border-radius: 13px;
			background: none;
			transition: .3s ease-in-out;
			&.on {
				color: $green;
				background: #FFF;
			}
			&:not(.on):hover {
				color: $black;				
			}
		}
	}
	.track {
		margin-bottom: 10px;
	}
	#tracks {
		transition: .3s ease-in-out;
		&.swap {
			opacity: 0;
		}
		span {
			display: block;
		}
	}*/
}
#search {
	z-index: 105;
}
body.modal-search {
	#left {
		filter: none !important;
		pointer-events: auto !important;		
	}
}
</style>