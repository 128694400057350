<template lang="pug">
include ../pug/svg
div.album(@click="setHex()" :class="{covered:coverLoaded,labelled:labelLoaded}")
	em {{stockLabel(album)}}
		//-strong {{album.stock}}
	hgroup
		h2 {{album.artistName}}
		h3 {{album.title}}
	picture
		div.vinyl(:style="recordStyle(album.vinylColour)")
			img(src="/img/vinyl.png" alt="vinyl" :style="imgStyle(album.vinylColour)")
			div.label(:style="{ background: '#' + album.artworkLabelHex }")
				img(v-if="album.artworkLabel" :src="album.artworkLabel" alt="" @load="labelLoaded=true")
		img.cover(:src="album.artworkCover" :alt="album.artistName+' - '+album.title" @load="coverLoaded=true")
	ul
		li
			Price(:price="vatPrice")
		li
			button.icon.pulse(type="button" @click="addToBasket()" :disabled="!isBuyable")
				+svg(svg-filename="iconBasket")
				span Buy
				em.qty(aria-label="Items in basket:") {{basketQty}}
		li
			button.icon.pulse(type="button" @click="viewAlbum()")
				+svg(svg-filename="iconView")
				span View
		li
			button.icon.pulse(type="button")
				+svg(svg-filename="iconListen")
				span Listen
</template>

<script>
import Price from '../components/Price';

export default {
	name: 'Album',
	emits: ['addedToBasket','viewAlbum'],
	components: {
		Price,
	},
	props: {
		album: {
			type: Object,
			required: true
		},
		index: {
			type: Number,
			required: true
		},
		pandp: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			coverLoaded: false,
			labelLoaded: false,
			backLoaded: false,
		}
	},
	computed: {
		vinylColours() {
			return this.$store.state.albums.vinylColour;
		},
		stockLabel() {
			return (album) => {
				let label;

				if (album.state === 'soon') {
					label = 'Coming Soon';

				} else if (album.state === 'funding' && album.stock) {
					label = 'Funded';				

				} else if (album.state === 'preorder' && album.stock) {
					label = 'Pre-Order';

				} else if (album.state === 'stock' && album.stock > 25) {
					label = 'In Stock';

				} else if (album.state === 'stock' && album.stock && album.stock <= 25) {
					label = 'Low Stock';

				} else {
					label = 'Out of Stock';
				}				
				
				return label;
			};
		},		
		vatCharge() {
			let vat = (this.album.price * this.pandp.vatPct);
			return Math.round((vat + Number.EPSILON) * 100) / 100;
		},
		vatPrice() {
			return (this.album.price + this.vatCharge).toFixed(2);
		},
		basketQty() {
			return this.$store.getters['basket/itemQty'](this.album, 'album') || '';
		},
		isBuyable() {
			return (!this.album.mintsoftProductId || !this.album.stock || this.album.state === 'soon') ? false : true;
		},
	},
	methods: {
		recordStyle(colour) {
//			const option = this.selectedColour;
			const option = this.vinylColours.find(c => c.value === colour) || null;
			return option.style + ':' + option.colour + ';';
		},
		imgStyle(colour) {
//			const option = this.selectedColour;
			const option = this.vinylColours.find(c => c.value === colour) || null;
			return (option.blend !== null) ? 'mix-blend-mode:' + option.blend + ';' : '';
		},
/*		vinylbgColour(colour) {
			const vinylColour = this.vinylColours.find(c => c.value === colour) || null;
			return (vinylColour.blend !== null) ? 'background-color:' + vinylColour.colour + ';' : '';
		},
		vinylImgColour(colour) {
			const vinylColour = this.vinylColours.find(c => c.value === colour) || null;
			return (vinylColour.blend !== null) ? 'mix-blend-mode:' + vinylColour.blend + ';' : '';
		},*/
		loadedCover() {
			this.coverLoaded = true;
		},
		loadedLabel() {
			this.labelLoaded = true;
		},
		loadedBack() {
			this.backLoaded = true;
		},
		addToBasket() {
			this.$store.dispatch('basket/addToBasket', {item: this.album, type: 'album'});
			// notify App.vue
			this.$emit('addedToBasket');			
		},
		viewAlbum() {
			// notify Shop.vue
			this.$emit('viewAlbum', this.album, this.index);
		},
		setHex() {
			if (this.album.hex) {
//				this.$store.dispatch('css/background', this.album.hex);
			}
		},
		loadArtworkBack() {
			const imgUrl = this.album.artworkBack;
			
			if (imgUrl) {
				let img = new Image();
				
				img.onload = function() {
					this.loadedBack();
				}.bind(this);
				
				img.src = imgUrl;
			}
		},
	},
	mounted() {
		this.loadArtworkBack();
	},	
};
</script>

<style lang="scss">
	#shop {
		.album {
			.label {
				background-image: none !important;
			}
			.vinyl {
				transition: all .3s ease-in-out, box-shadow .3s .3s ease-in-out;
				box-shadow: 0 5px 15px rgba(#000, .3);
background: url(/img/vinyl.png) no-repeat center;
background-size: 100%;
&:before {
	display: none;
}
>img {
background: none;
}
			}
			img.cover,
			picture:before,
			picture:after {
				opacity: 0;
				transition: all .3s ease-in-out, opacity .3s .3s ease-in-out;
			}
			&:hover {
				picture {
					&:before,
					&:after,
					>img {
						transform: translateX(-20px);		
					}
				}
				.vinyl {
//					transform: translateX(35%);
					transform: translateX(80px) rotate(135deg);
					.label {
						transform: rotate(50deg);
					}
				}
				li {
					opacity: 1;
					transform: translateY(0) !important;
				}
			}
			&:not(.covered) {
/*				picture {
					&:before,
					&:after,
					>img {
						transform: translateX(-10%);		
					}
				}*/
			}
			&.covered {
				.vinyl {
					box-shadow: none;
				}
				picture {
					&:before,
					&:after,
					>img {
						opacity: 1;
					}
				}
			}
			&.labelled {
				.label >img {
					opacity: 1;
				}
			}
		}
	}
</style>